import React from 'react'

const footer = () => {
    return (
        <div>
            {/* <h2>Footer</h2> */}
        </div>
    )
}

export default footer
